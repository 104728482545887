.level-select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.level-select .levels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
}

.level-select .card {
    display: block;
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: relative;
    border-image: url(img/temp.png) 160 round;
    border-style: solid;
    border-width: 16px;
}

.level-select .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
}

.level-select .card:hover img {
    scale: 1.3;
}

.level-select .card p {
    position: absolute;
    margin: 0;
    color: initial;
    font-size: 1.5rem;
    z-index: 1;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    transition: 0.5s;
}

.level-select .card:hover p {
    scale: 2;
    color: white;
    translate: -50% -50%;
}

.level-select .card p::before {
    content: '';
    display: block;
    background-color: white;
    opacity: 1;
    translate: -50% -50%;
    rotate: 45deg;
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    z-index: -1;
    transition: 1s;
}

.level-select .card:hover p::before {
    opacity: 0.5;
    rotate: 315deg;
    scale: 5.1;
}
